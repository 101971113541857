.advantage{
    display: flex;
    flex-direction: column;

    img{
        height: 4.4rem;
        align-self: flex-start;
    }

    h5{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 4.5rem;
        line-height: 110%;
        margin-top: 1.6rem;
        margin-bottom: 2rem;
    }

    p{
        font-weight: 300;
        font-size: 1.8rem;
        line-height: 150%;
        max-width: 34.8rem;
    }
}

@media only screen and (max-width: 480px){
    .advantage{
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    
        img{
            height: 2rem;
            align-self: flex-start;
        }
    
        h5{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 3.2rem;
            line-height: 110%;
            margin-top: 1rem;
            margin-bottom: 1.6rem;
        }
    
        p{
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 150%;
            max-width: unset;
        }
    }
}