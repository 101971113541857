.above{
    height: calc(100vh - 21.6rem);
    display: flex;
    justify-content: space-between;
    padding: 15.8rem 12.1rem 5.8rem;
    position: relative;
    background-repeat: no-repeat!important;
    background-size: cover!important;

    > div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(2){
            margin-right: 4rem;
        }

        &.bottom{
            justify-content: flex-end;
        }

        h1, em{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 12rem;
            line-height: 125%;
            color: #FAFAFA;
            max-width: 60rem;
            margin-bottom: 20rem;
        }

        &:first-child{
            justify-content: center;
        }

        &:last-child{
            text-align: center;
        }

        img{
            height: 68vh;
            object-fit: cover;
        }

        p, a{
            font-weight: 300;
            font-size: 2rem;
            line-height: 161%;
            color: #FAFAFA;
            text-align: left;
            max-width: 46.8rem;
        }

        a{
            text-decoration: underline;
        }
    }

    .scroll{
        position: absolute;
        bottom: 9.2rem;
        left: 50%;
        transform: translateX(-50%);
        
        svg{
            cursor: pointer;   
        }
    }
}

@media only screen and (max-width: 1024px) and (orientation:portrait){
    .above{
        > div.bottom{
            justify-content: center;
            margin-top: 30rem;

            p{
                font-size: 2.4rem;
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .above{
        flex-direction: column;
        padding: 6.8rem 3.8rem;
        justify-content: center;
        min-height: calc(100vh - 13.6rem);

        .changeMobile{
            bottom: 3rem!important;
        }

        > div{
            &.bottom{
                margin-top: 10rem;

                p{
                    font-size: 1.8rem;
                }
            }

            &:first-child{
                height: 28rem;
            }
    
            &:nth-child(2){
                order: 1;
            }

            h1, em{
                font-size: 4.4rem!important;
                margin-bottom: unset;
                margin-top: 7rem;
            }

            img{
                height: 38rem;
            }

            p{
                font-size: 1.4rem;
            }
        }

        .scroll{
            display: none;
        }
    }
}