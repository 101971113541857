.solution{
    display: flex;
    justify-content: space-between;
    padding: 13.2rem 10.5rem 10.3rem;

    > div{
        &:first-child{
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.2rem;
                margin-bottom: 7rem;
            }
        }

        h2{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 6.7rem;
            line-height: 104%;
            color: #000;
        }
    }
}

.solutionMarquee div[class*="marquee__"]{
    span{
        width: 66rem;
    }

    > div{
        animation: newMarquee 10s linear infinite;
        animation-play-state: running;
    }
}

.lastImage{
    display: block;
    text-align: center;
    
    img{
        margin: 0 auto;
        max-width: 50rem;
    }
}

@keyframes newMarquee {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(calc(-50% + 22vw), 0, 0);
	}
}

.works{
    padding: 0;
    position: relative;

    h3{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 1.2rem;
        padding-left: 10.5rem;
    }

    .worksHolder{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: hidden;
        padding-left: 10.5rem;
    }

    .next{
        position: absolute;
        bottom: 6rem;
        right: 5.2rem;
        opacity: 1;
        transition: all .4s ease-in-out;
        cursor: pointer;
        pointer-events: all;

        &.hide{
            opacity: 0;
            pointer-events: none;
        }
    }

    .prev{
        position: absolute;
        bottom: 6rem;
        left: 5.2rem;
        opacity: 0;
        transition: all .4s ease-in-out;
        cursor: pointer;
        pointer-events: none;

        &.show{
            opacity: 1;
            pointer-events: all;
        }

        svg{
            transform: rotate(180deg);
        }
    }
}

.advantages{
    padding: 18.2rem 10.5rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-bottom: 7rem;
    }

    > div{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 6.9rem;
    }
}

.higlightImage{
    img{
        width: 100%;
        height: 55rem;
        object-fit: cover;
    }
}

.aplicability{
    padding: 14.7rem 10.5rem 10rem;
    background-color: #242241;
    margin-top: -1rem;

    h3{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 2.5rem;
        color: #FAFAFA;
    }
}

.form{
    padding: 17.3rem 12.1rem 18.5rem;
    background-color: #242241;
    position: relative;

    .sheetsImageHolder{
        position: absolute;
        right: 6rem;
        top: -2%;
        display: flex;
        flex-direction: column;
        opacity: 0;
        overflow-x: hidden;
    
        img{
            max-width: 12rem;
            max-height: 12rem;
            object-fit: contain;
    
            &:nth-child(2){
                margin-bottom: -5rem;
                z-index: 3;
            }
    
            &:last-child{
                max-width: 10rem;
            }
        }
    }

    h4{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 11rem;
        line-height: 101%;
        color: #FAFAFA;
        margin-bottom: 3rem;

        span{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 11rem;
            line-height: 101%;
            color: #0156FF;
        }
    }

    a{
        color: #FAFAFA!important;

        svg{
            path{
                fill: #FAFAFA!important;
            }
            circle{
                stroke: #FAFAFA!important;
            }
        }
    }
}

@media only screen and (max-width: 1024px) and (orientation:portrait){
    .works{
        .next, .prev{
            transform: scale(.5);
        }
    }
}

@media only screen and (max-width: 480px){
    .solution{
        display: flex;
        justify-content: space-between;
        padding: 4rem 3.8rem 6rem;
    
        > div{
            &:first-child{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                h3{
                    font-family: 'GTFlexa', sans-serif, serif;
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    margin-bottom: 3rem;
                }
            }
    
            h2{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 3.4rem;
                line-height: 104%;
                color: #0156FF;
            }
        }
    }
    
    .works{
        padding: 0;
        position: relative;
    
        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: 1.2rem;
            padding-left: 3.8rem;
        }
    
        .worksHolder{
            display: flex;
            flex-wrap: nowrap;
            overflow-x: hidden;
            padding-left: 3.8rem;
        }
    
        .next{
            position: absolute;
            bottom: 12rem;
            right: 2rem;
            opacity: 1;
            transition: all .4s ease-in-out;
            cursor: pointer;
            pointer-events: all;
            transform: scale(.5);
    
            &.hide{
                opacity: 0;
                pointer-events: none;
            }
        }
    
        .prev{
            position: absolute;
            bottom: 12rem;
            left: 2rem;
            opacity: 0;
            transition: all .4s ease-in-out;
            cursor: pointer;
            pointer-events: none;
            transform: scale(.5);
    
            &.show{
                opacity: 1;
                pointer-events: all;
            }
    
            svg{
                transform: rotate(180deg);
            }
        }
    }
    
    .advantages{
        padding: 4rem 3.8rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: unset;
    
        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.8rem;
            margin-bottom: 3rem;
        }
    
        > div{
            display: grid;
            grid-template-columns: 1fr;
            column-gap: unset;
            row-gap: 2rem;
        }
    }
    
    .higlightImage{
        img{
            width: 100%;
            height: 55rem;
            object-fit: cover;
        }
    }
    
    .aplicability{
        padding: 7rem 3.8rem 4rem;
        background-color: #242241;
        margin-top: -1rem;
    
        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 2rem;
            color: #FAFAFA;
        }
    }

    .lastImage{
        img{
            max-width: 100%;
        }
    }
    
    .form{
        padding: 3rem 3.8rem 5rem;
        background-color: #242241;

        .sheetsImageHolder{
            display: none;
        }
    
        h4{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 5rem;
            line-height: 101%;
            color: #FAFAFA;
            margin-bottom: 2rem;
    
            span{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 5rem;
                line-height: 101%;
                color: #0156FF;
            }
        }
    
        a{
            color: #FAFAFA!important;
    
            svg{
                path{
                    fill: #FAFAFA!important;
                }
                circle{
                    stroke: #FAFAFA!important;
                }
            }
        }
    }
}