.privacyHolder{
    padding: 10rem 12rem 0;

    h1{
        font-family: GT Flexa Trial;
        font-weight: 400;
        font-size: 3.4rem;
        line-height: 4.2rem;
        margin-bottom: 1.4rem;
    }

    p{
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 141.3%;
        margin-bottom: 2rem;
    }
}