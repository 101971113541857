@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800&display=swap');
@font-face {
    font-family: "GTFlexa";
    font-weight: 400;
    src: url(assets/fonts/GTFlexa/GT-Flexa-Regular.woff2) format("woff2"), url(assets/fonts/GTFlexa/GT-Flexa-Regular.woff) format("woff"), url(assets/fonts/GTFlexa/GT-Flexa-Regular.ttf) format("truetype")
}

@font-face {
    font-family: "GTFlexa";
    font-weight: 300;
    src: url(assets/fonts/GTFlexa/GT-Flexa-Light-Trial.woff2) format("woff2"), url(assets/fonts/GTFlexa/GT-Flexa-Light-Trial.woff2) format("woff"), url(assets/fonts/GTFlexa/GT-Flexa-Light-Trial.otf) format("opentype")
}


html { font-size: 62.5%; scroll-behavior: smooth; } /* font-size: 62.5% now means that 1.0 rem = 1rem */
*{font-size: 1.4rem;line-height: 1.6rem; }

body{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    color: #1F2023;
    font-family: 'Roboto', sans-serif, serif;
    background-color: #FAFAFA;
    overflow-x: hidden;

    &.stop{
      overflow: hidden;
    }

    a, p, h1, h2, h3, h4, h5, h6, input{
        margin: 0;
        padding: 0;
    }

    a{
        text-decoration: none;
        color: #1F2023;

        p{
            cursor: pointer;
        }
    }

    p{
        cursor: default;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 1728px) {
    html {
        font-size: 10px;
    }
}
@media (max-width: 1536px) {
    html {
        font-size: 10px;
    }
}
@media (max-width: 1344px) {
    html {
        font-size: 9px;
    }
}
@media (max-width: 1152px) {
    html {
        font-size: 8px;
    }
}
@media (max-width: 960px) {
    html {
        font-size: 7px;
    }
}
@media (max-width: 768px) {
    html {
        font-size: 6px;
    }
}
@media (max-width: 767px) {
    html {
        font-size: 10px;
    }
}