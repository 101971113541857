.aplicability{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #777779;
    padding: 4.8rem 1.6rem;

    &:nth-child(2){
        border-top: 1px solid #777779;
    }

    .questionHolder{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .question{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 5.4rem;
            line-height: 6.6rem;
            color: #FAFAFA;
        }

        svg{
            transition: all .4s ease-in-out;

            path{
                fill: #FAFAFA;
            }
        }

        &.open{
            svg{
                transform: rotate(45deg);
            }
        }
    }

    .answerHolder{
        opacity: 0;
        max-height: 0;
        visibility: hidden;
        transition: all .4s ease-in-out;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 4.5rem;
        row-gap: 5rem;

        &.open{
            opacity: 1;
            max-height: 50rem;
            visibility: visible;
            margin-top: 4.8rem;
        }

        h5{
            font-weight: 400;
            font-size: 2rem;
            line-height: 150%;
            color: #FAFAFA;
            position: relative;
            margin-left: .9rem;
            max-width: 23rem;
            height: 6rem;

            &:before{
                content: "";
                position: absolute;
                top: 1.2rem;
                left: -.9rem;
                width: .4rem;
                height: .4rem;
                border-radius: 50%;
                background-color: #0156FF;
            }
        }

        p{
            font-weight: 300;
            font-size: 1.85rem;
            line-height: 150%;
            color: #FAFAFA;
            margin-left: .9rem;
            margin-top: .7rem;
            max-width: 23rem;
        }
    }
}

@media only screen and (max-width: 480px){
    .aplicability{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #777779;
        padding: 1.8rem 0;
    
        &:nth-child(2){
            border-top: 1px solid #777779;
        }
    
        .questionHolder{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
    
            .question{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 2.4rem;
                line-height: 3rem;
                color: #FAFAFA;
            }
    
            svg{
                transition: all .4s ease-in-out;
                transform: scale(.5);
    
                path{
                    fill: #FAFAFA;
                }
            }
    
            &.open{
                svg{
                    transform: scale(.5) rotate(45deg);
                }
            }
        }
    
        .answerHolder{
            opacity: 0;
            max-height: 0;
            visibility: hidden;
            transition: all .4s ease-in-out;
            display: flex;
            flex-direction: column;
            grid-template-columns: unset;
            column-gap: unset;
            row-gap: unset;
    
            &.open{
                opacity: 1;
                max-height: 150rem;
                visibility: visible;
                margin-top: 0;
            }
    
            h5{
                font-weight: 400;
                font-size: 2rem;
                line-height: 150%;
                color: #FAFAFA;
                position: relative;
                margin-left: .9rem;
                max-width: 23rem;
                height: unset;
                margin-top: 3rem;
    
                &:before{
                    content: "";
                    position: absolute;
                    top: 1.2rem;
                    left: -.9rem;
                    width: .4rem;
                    height: .4rem;
                    border-radius: 50%;
                    background-color: #0156FF;
                }
            }
    
            p{
                font-weight: 300;
                font-size: 1.4rem;
                line-height: 150%;
                color: #FAFAFA;
                margin-left: .9rem;
                margin-top: .7rem;
                max-width: unset;
            }
        }
    }
}