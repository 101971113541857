header{
    display: flex;
    justify-content: space-between;
    padding: 3.4rem 12.1rem 3.7rem;
    position: fixed;
    width: calc(100vw - 24.2rem);
    background-color: transparent;
    top: 0;
    left: 0;
    border-bottom: 1px solid #FAFAFA22;
    z-index: 32;
    transition: all .4s ease-in-out;

    > a{
        z-index: 31;

        img{
            z-index: 31;
        }
    }

    &.open, &.hasScroll{
        background-color: #0156FF!important;
    }

    .menuButton{
        display: flex;
        align-items: center;
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 98.5%;
        text-transform: uppercase;
        color: #FAFAFA;
        cursor: pointer;
        z-index: 31;

        svg{
            margin-left: 1.5rem;
            transition: all .4s ease-in-out;
        }

        &.open{
            svg{
                transform: rotate(45deg);
            }
        }
    }

    nav{
        position: fixed;
        padding: 2.7rem 32rem 11rem;
        width: calc(100vw - 64rem);
        top: -51rem;
        left: 0;
        background-color: #0156FF;
        display: flex;
        justify-content: space-between;
        z-index: 30;
        transition: all .4s ease-in-out;

        &.open{
            top: 10rem;
            border-bottom: 1px solid #FAFAFA22;
        }

        .visual{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            img{
                height: 25rem;
            }

            p{
                margin-top: 5rem;
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.2rem;
                color: #FAFAFA;
            }

            a{
                font-weight: 300;
                font-size: 2rem;
                line-height: 161%;
                color: #FAFAFA;
                margin-top: 1.1rem;
                position: relative;
                width: fit-content;

                &:after{
                    content: "";
                    width: 0;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    background-color: #FAFAFA;
                    height: 1px;
                    transition: all .4s ease-in-out;
                }

                &:hover{
                    &:after{
                        width: 100%;
                    }
                }
            }
        }

        .text{
            display: flex;
            flex-direction: column;

            a{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 6.7rem;
                line-height: 98.5%;
                color: #FAFAFA;
                margin-bottom: 3rem;
                position: relative;
                width: fit-content;

                &:last-child{
                    margin-bottom: 0;
                }

                &:after{
                    content: "";
                    width: 0;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    background-color: #FAFAFA;
                    height: 1px;
                    transition: all .4s ease-in-out;
                }

                &:hover{
                    &:after{
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px){
    header{
        padding: 3.4rem 3.8rem ;
        width: calc(100vw - 7.6rem);

        img{
            max-width: 13rem;
        }

        nav{
            position: fixed;
            padding: 2.7rem 3.8rem;
            width: calc(100vw - 7.6rem);
            height: calc(100vh - 15.4rem);
            top: -100vh;
            left: 0;
            background-color: #0156FF;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            z-index: 30;
            transition: all .4s ease-in-out;
    
            &.open{
                top: 9.3rem;
            }
    
            .visual{
                display: flex;
                flex-direction: column;
                order: 2;
                margin-top: 4rem;
    
                img{
                    display: none;
                }
    
                p{
                    margin-top: 5rem;
                    font-family: 'GTFlexa', sans-serif, serif;
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    color: #FAFAFA;
                }
    
                a{
                    font-weight: 300;
                    font-size: 1.8rem;
                    line-height: 161%;
                    color: #FAFAFA;
                    margin-top: 1.1rem;
                    position: relative;
                    width: fit-content;
    
                    &:after{
                        content: "";
                        width: 0;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: #FAFAFA;
                        height: 1px;
                        transition: all .4s ease-in-out;
                    }
    
                    &:hover{
                        &:after{
                            width: 100%;
                        }
                    }
                }
            }
    
            .text{
                display: flex;
                flex-direction: column;
                order: 1;
    
                a{
                    font-family: 'GTFlexa', sans-serif, serif;
                    font-weight: 400;
                    font-size: 5rem;
                    line-height: 98.5%;
                    color: #FAFAFA;
                    margin-bottom: 3rem;
                    position: relative;
                    width: fit-content;
    
                    &:last-child{
                        margin-bottom: 0;
                    }
    
                    &:after{
                        content: "";
                        width: 0;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: #FAFAFA;
                        height: 1px;
                        transition: all .4s ease-in-out;
                    }
    
                    &:hover{
                        &:after{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}