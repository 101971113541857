.opportunity{
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: calc(54rem - 10.8rem);
    min-width: calc(71rem - 11.4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4.8rem 5.7rem 6rem;
    transition: all .4s ease-in-out;

    h4{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 4.5rem;
        line-height: 110%;
        color: #FAFAFA;
        max-width: 30.4rem;
        z-index: 2;
    }

    p{
        font-weight: 300;
        font-size: 2rem;
        line-height: 150%;
        color: #FAFAFA;
        max-width: 31rem;
        z-index: 2;
    }
}

@media only screen and (max-width: 480px){
    .opportunity{
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        min-height: calc(30rem - 8rem);
        min-width: calc(100% - 6.4rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4rem 3.2rem 4rem;
        transition: all .4s ease-in-out;
    
        h4{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 3rem;
            line-height: 110%;
            color: #FAFAFA;
            max-width: 30.4rem;
        }
    
        p{
            font-weight: 300;
            font-size: 1.8rem;
            line-height: 150%;
            color: #FAFAFA;
            max-width: 31rem;
        }
    }
}