.button{
    font-family: 'GTFlexa', sans-serif, serif;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #0156FF;
    display: flex;
    align-items: center;
    
    svg{
        margin-left: 1rem;
    }

    &.special{
        color: #242241;

        svg{
            margin-left: 2.5rem;
            transform: rotate(-90deg);

            path{
                fill: #242241;
            }

            circle{
                stroke: #242241;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .button{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #0156FF;
        display: flex;
        align-items: center;
        
        svg{
            margin-left: 2rem;
        }
    
        &.special{
            color: #242241;
    
            svg{
                margin-left: 2rem;
                transform: rotate(-90deg) scale(.7);
    
                path{
                    fill: #242241;
                }
    
                circle{
                    stroke: #242241;
                }
            }
        }
    }
}