.sheetsImageHolder{
    position: absolute;
    right: 10rem;
    top: 30%;
    display: flex;
    flex-direction: column;
    opacity: 0;

    img{
        max-width: 12rem;
        max-height: 12rem;
        object-fit: contain;

        &:nth-child(2){
            margin-bottom: -5rem;
            z-index: 3;
        }

        &:last-child{
            max-width: 10rem;
        }
    }
}

.about{
    margin-top: 12rem;
    display: flex;
    align-items: center;
    position: relative;

    > img{
        position: absolute;
        right: 3rem;
        top: -30%;
    }

    > div{
        &:first-child{
            margin-right: 12.5rem;
        }

        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: 2.7rem;
        }

        h2{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 4.8rem;
            line-height: 104%;
            margin-bottom: 3rem;
            max-width: 62rem;

            &:nth-last-child(2){
                margin-bottom: 4.7rem;
            }
        }
    }
}

.videoSection{
    display: flex;
    justify-content: center;
    margin-top: 12rem;

    > video{
        width: 100%;
        height: 70vh;
    }
}

.isTime{
    padding: 12.4rem 12.1rem 5rem;
    display: flex;
    justify-content: space-between;

    > div{
        display: flex;
        flex-direction: column;

        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: .9rem;
        }
    
        h2{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 12rem;
            line-height: 101%;
            margin-bottom: 3rem;
            color: #0156FF;
        }

        img{
            object-fit: cover;
            height: 55vh;
            width: 100%;
            object-position: 50% 55%;
            margin-bottom: 8rem;
            margin-top: 8rem;
        }

        p{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 3rem;
            line-height: 4rem;
            max-width: 100rem;
            margin-bottom: 3rem;
        }
    }
}

.higlightImage{
    img{
        width: 100%;
        height: 60rem;
        object-fit: cover;
    }
}

.solution{
    padding: 14.7rem 0 11.7rem 12.1rem;
    background-color: #242241;
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    position: relative;

    h3{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 2rem;
        color: #0156FF;
    }

    h2{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 4.8rem;
        line-height: 104%;
        margin-bottom: 10rem;
        max-width: 82rem;
        color: #FAFAFA;
    }

    .sheetsImageHolder2{
        position: absolute;
        right: 8rem;
        top: 30%;
        display: flex;
        flex-direction: column;
        opacity: 0;
    
        img{
            max-width: 12rem;
            max-height: 12rem;
            object-fit: contain;
    
            &:nth-child(2){
                margin-bottom: -5rem;
                z-index: 3;
            }
    
            &:last-child{
                max-width: 10rem;
            }
        }
    }

    // img{
    //     position: absolute;
    //     right: 15rem;
    //     bottom: -16rem;
    //     width: 24rem;
    //     z-index: 1;
    // }
}

.talk{
    padding: 12rem 18.7rem 32rem;
    background-color: #F1F3F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h2{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 11rem;
        line-height: 110%;
        text-align: center;
        max-width: 100rem;
        margin-bottom: 5.8rem;

        span{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 11rem;
            line-height: 110%;
            text-align: center;
            max-width: 100rem;
            margin-bottom: 5.8rem;
            color: #0156FF;
        }
    }

    img{
        position: absolute;
        width: 40%;
        left: 50%;
        transform: translateX(-50%);
        bottom: -18vh;
    }
}

@media only screen and (max-width: 480px){
    .sheetsImageHolder, .sheetsImageHolder2{
        display: none!important;
    }
    .about{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: unset;
        padding: 0 3.8rem;
    
        > div{
            &:first-child{
                margin-right: unset;
                margin-bottom: 3rem;
            }

            img{
                width: 100%;
            }
    
            h3{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2rem;
                margin-bottom: 2.7rem;
            }
    
            h2{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 2.6rem;
                line-height: 104%;
                margin-bottom: 3rem;
                max-width: unset;
    
                &:nth-last-child(2){
                    margin-bottom: 4.7rem;
                }
            }
        }
    }
    
    .videoSection{
        display: flex;
        flex-direction: column;
        margin-top: 6rem;
    
        > video{
            width: 100%;
            height: 50vh;
            margin-top: 2rem;
            object-fit: cover;
            object-position: 70%;
        }
    }
    
    .isTime{
        padding: 8.4rem 3.8rem 5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        > div{
            display: flex;
            flex-direction: column;
    
            h3{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2rem;
                margin-bottom: .9rem;
            }
        
            h2{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 5rem;
                line-height: 101%;
                margin-bottom: 3rem;
                color: #0156FF;
            }
    
            img{
                object-fit: cover;
                height: 50vh;
                width: auto;
                object-position: top;
                margin-bottom: 3rem;
                max-width: 100%;
            }
    
            p{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 2.4rem;
                line-height: 3rem;
                max-width: unset;
                margin-bottom: 3rem;
            }
        }
    }
    
    .higlightImage{
        img{
            width: 100%;
            height: 30rem;
            object-fit: cover;
        }
    }
    
    .solution{
        padding: 3.8rem 3.8rem;
        background-color: #242241;
        display: flex;
        flex-direction: column;
        margin-top: -1rem;
        position: relative;
    
        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 2rem;
            color: #FAFAFA;
        }
    
        h2{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 3.6rem;
            line-height: 104%;
            margin-bottom: 10rem;
            max-width: unset;
            color: #FAFAFA;
        }
    
        img{
            position: absolute;
            right: 3rem;
            bottom: -12rem;
            width: 14rem;
            z-index: 1;
        }
    }
    
    .talk{
        padding: 4rem 3.8rem 7rem;
        background-color: #F1F3F5;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    
        h2{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 5rem;
            line-height: 110%;
            text-align: center;
            max-width: unset;
            margin-bottom: 1.8rem;
    
            span{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 5rem;
                line-height: 110%;
                text-align: center;
                max-width: unset;
                margin-bottom: 5.8rem;
                color: #0156FF;
            }
        }
    
        img{
            position: absolute;
            width: 40%;
            left: 50%;
            transform: translateX(-50%);
            bottom: -6.3rem;
        }
    }   
}