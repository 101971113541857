footer{
    display: flex;
    flex-direction: column;
    padding: 9.7rem 12.1rem 3.8rem;

    .footerInfo{
        display: flex;
        justify-content: space-between;
        margin-top: 6.8rem;
        margin-bottom: 7.6rem;
        
        > div{
            display: flex;
            flex-direction: column;

            h4{
                margin-bottom: 1.7rem;
                font-family: GT Flexa Trial;
                font-weight: 400;
                font-size: 2.4rem;
                line-height: 2.9rem;
            }

            a{
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 165%;
                margin-bottom: 1rem;
                position: relative;
                width: fit-content;

                &:last-child{
                    margin-bottom: 0;
                }

                &:after{
                    content: "";
                    width: 0;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    background-color: #0156FF;
                    height: 1px;
                    transition: all .4s ease-in-out;
                }

                &:hover{
                    &:after{
                        width: 100%;
                    }
                }
            }
        }

        .newsletter{
            width: 47rem;

            p{
                max-width: 30.3rem;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 141.3%;
                margin-bottom: 1.8rem;

                &.rgpd{
                    font-size: 1.3rem;
                    line-height: 1.5rem;
                    color: #777779;
                    margin-bottom: 0;
                    margin-top: 1.6rem;
                }
            }

            form{
                input{
                    font-family: 'GTFlexa', sans-serif, serif;
                    font-weight: 400;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    padding: 1.6rem 2.1rem;
                    border: none;
                    outline: none;
                    -webkit-appearance: none;

                    &[type="email"]{
                        border: 1px solid #1F2023;
                        width: 55%;

                        &.small{
                            font-size: 1.2rem;
                        }
                    }

                    &[type="button"]{
                        color: #fafafa;
                        background-color: #0156FF;
                        padding: 1.7rem 0;
                        width: 35%;
                    }
                }
            }
        }
    }

    .notes{
        a{
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 1.5rem;
            margin-right: 3.7rem;
            color: #777779;

            &:last-child{
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    footer{
        flex-direction: column;
        padding: 5rem 3.8rem 3rem;
        align-items: center;

        > div{
            &:nth-child(1){
                order: 2;
                margin-bottom: 4rem;
            }
            &:nth-child(2){
                order: 1;
            }
            &:nth-child(3){
                order: 3;
            }
        }

        .footerInfo{
            flex-direction: column;
            align-items: center;
            margin-top: 6.8rem;
            margin-bottom: 4rem;
            
            > div{
                display: flex;
                margin-top: 6rem;
                align-items: center;

                &:nth-child(1){
                    order: 2;
                }
                &:nth-child(2){
                    order: 3;
                }
                &:nth-child(3){
                    order: 4;
                }
    
                h4{
                    margin-bottom: 1.7rem;
                    font-family: GT Flexa Trial;
                    font-weight: 400;
                    font-size: 2.4rem;
                    line-height: 2.9rem;
                    text-align: center;
                }
    
                a{
                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 165%;
                    margin-bottom: 1rem;
                    position: relative;
                    width: fit-content;
                    text-align: center;
    
                    &:last-child{
                        margin-bottom: 0;
                    }
    
                    &:after{
                        content: "";
                        width: 0;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: #0156FF;
                        height: 1px;
                        transition: all .4s ease-in-out;
                    }
    
                    &:hover{
                        &:after{
                            width: 100%;
                        }
                    }
                }
            }
    
            .newsletter{
                width: 100%;
                order: 1;
                margin-top: 0;
    
                p{
                    max-width: 30.3rem;
                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 141.3%;
                    margin-bottom: 1.8rem;
                    text-align: center;
    
                    &.rgpd{
                        font-size: 1rem;
                        line-height: 1.5rem;
                        color: #777779;
                        margin-bottom: 0;
                        margin-top: 1.6rem;
                    }
                }
    
                form{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    input{
                        font-family: 'GTFlexa', sans-serif, serif;
                        font-weight: 400;
                        font-size: 1.6rem;
                        line-height: 1.9rem;
                        padding: 1.6rem 2.1rem;
                        border: none;
                        outline: none;
                        -webkit-appearance: none;
    
                        &[type="email"]{
                            border: 1px solid #1F2023;
                            text-align: center;
                            width: calc(100% - 4.4rem);
                        }
    
                        &[type="button"]{
                            color: #fafafa;
                            background-color: #0156FF;
                            padding: 1.7rem 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}