.work{
    min-width: calc(100vw - 12vw);
    display: flex;
    justify-content: space-between;
    border: 1px solid #B8B9B9;
    margin-right: 1rem;
    transition: all .4s ease-in-out;

    > div{
        width: 50%;
        max-height: 90vh;

        img{
            height: 100%;
            max-height: 90vh;
            max-width: 100%;
            object-fit: cover;
        }

        &:first-child{
            padding: 4rem 0 4rem 6.3rem;
        }

        h5{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.4rem;
            color: #0156FF;
            margin-bottom: 4.2rem;
        }

        h4{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 5.5rem;
            line-height: 104%;
            margin-bottom: 3.4rem;
        }

        p{
            font-weight: 400;
            font-size: 2rem;
            line-height: 150%;
            margin-bottom: 3.4rem;
            max-width: 45rem;
        }

        ul{
            padding-left: 2.2rem;

            li{
                font-weight: 400;
                font-size: 2rem;
                line-height: 150%;
                margin-bottom: 2rem;
                max-width: 36.8rem;
                list-style-image: url(../../../assets/svg/tick.svg);

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) and (orientation:portrait){
    .work{
        > div{
            img{
                max-height: 51vh;
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .work{
        min-width: calc(100vw - 6rem);
        display: flex;
        flex-direction: column;
        justify-content: unset;
        border: 1px solid #B8B9B9;
        margin-right: 1rem;
        transition: all .4s ease-in-out;
    
        > div{
            width: 100%;

            img{
                height: 20rem;
                width: 100%;
                max-width: 100%;
                object-fit: cover;
            }
    
            &:first-child{
                padding: 3rem 0 3rem 2rem;
                width: calc(100% - 2rem);
            }

            &:last-child{
                margin-top: auto;
            }
    
            h5{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 2rem;
                color: #0156FF;
                margin-bottom: 2rem;
            }
    
            h4{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 3rem;
                line-height: 104%;
                margin-bottom: 2rem;
            }
    
            p{
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 150%;
                margin-bottom: 2rem;
                max-width: unset;
            }
    
            ul{
                padding-left: 1.2rem;
    
                li{
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 150%;
                    margin-bottom: 2.9rem;
                    max-width: 24rem;
                    list-style-image: url(../../../assets/svg/tick.svg);
    
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}