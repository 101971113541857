.support{
    display: flex;
    justify-content: space-between;

    > div{
        padding: 15rem 12rem;
        display: flex;
        flex-direction: column;
        width: 50%;

        h2{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 6.7rem;
            line-height: 104%;
            color: #FAFAFA;
            max-width: 40rem;
        }

        p{
            font-weight: 300;
            font-size: 1.8rem;
            line-height: 150%;
            color: #FAFAFA;
        }

        > div{
            margin-top: 5.5rem;
            display: flex;

            svg{
                max-width: 2.4rem;
                height: auto;
                margin-right: 2rem;
            }
        }

        a{
            color: #FAFAFA;

            svg{
                path{
                    fill: #FAFAFA;
                }
            }
        }

        &:first-child{
            background-color: #0156FF;
        }

        &:last-child{
            background-color: #242241;
        }
    }
}

.formHolder{
    padding: 0 10.5rem;
    margin: 14.8rem 0 11.5rem;

    h3{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 4.9rem;
        padding-bottom: 2.4rem;
        border-bottom: 1px solid #777779;
    }

    .positiveFeedback, .negativeFeedback, .fieldsFeedback{
        font-size: 2rem;
        line-height: 2.4rem;
        opacity: 0;
        max-height: 0;
        transition: all .5s ease-in-out;

        &.show{
            opacity: 1;
            margin-top: 2rem;
            max-height: unset;
        }
    }

    form{
        label{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 5.4rem;
            line-height: 6.6rem;
            color: #1F2023;

            span{
                font-size: 2.4rem;
                line-height: 3rem;
            }
        }

        input, textarea{
            background: none;
            outline: none;
            -webkit-appearance: none;
            border: none;
            border-bottom: 1px solid #B8B9B9;
            border-radius: 0;
            padding-bottom: 1.1rem;
            padding-left: 1rem;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 150%;
            color: #1F2023;
            font-family: 'Roboto', sans-serif, serif;

            &.error{
                border-bottom-color: #FF4A4A;
            }

            &[type="text"], &[type="email"], &[type="tel"]{
                width: 47rem;
                margin-right: 12rem;
            }

            &[type="radio"]{
                position: absolute;
                opacity: 0;
                cursor: pointer;
                width: 100%;
                height: 3.4rem;
                margin-left: -4rem;
                z-index: 2;
            }

            &.button{
                background-color: #0156FF;
                color: #FAFAFA;
                padding: 1.1rem 3rem;
                cursor: pointer;
            }
        }

        textarea{
            width: 47rem;
        }

        > div{
            > div{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 9rem;
                margin-top: 4.9rem;
                row-gap: 3rem;
                align-items: center;
                position: relative;

                > span{
                    position: absolute;
                    bottom: -2.4rem;
                    color: #FF4A4A;
                    display: none;

                    &.show{
                        display: unset;
                    }
                }

                > div{
                    align-items: center;
                    display: flex;
                    margin-right: 7rem;
                    position: relative;
                    padding-left: 4rem;

                    &:hover input ~ .checkmark {
                        border: 1px solid #1F2023;
                        border-radius: 100%;
                    }

                    .checkmark {
                        position: absolute;
                        top: -6px;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        border: 1px solid #1F2023;
                        border-radius: 100%;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                            top: 7px;
                            left: 7px;
                            width: 11px;
                            height: 11px;
                            border-radius: 50%;
                            background: #0156FF;
                        }
                    }

                    input:checked ~ .checkmark:after {
                        display: block;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .support{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        > div{
            padding: 8rem 3.8rem;
            display: flex;
            flex-direction: column;
            width: calc(100% - 3.8rem - 3.8rem);
    
            h2{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 2.6rem;
                line-height: 104%;
                color: #FAFAFA;
                max-width: unset;
            }
    
            p{
                font-weight: 300;
                font-size: 1.8rem;
                line-height: 150%;
                color: #FAFAFA;
            }
    
            > div{
                margin-top: 3rem;
                display: flex;
    
                svg{
                    max-width: 2.4rem;
                    min-width: 2.4rem;
                    height: auto;
                    margin-right: 2rem;
                    max-height: 2.5rem;
                }
            }
    
            a{
                color: #FAFAFA;
    
                svg{
                    path{
                        fill: #FAFAFA;
                    }
                }
            }
    
            &:first-child{
                background-color: #0156FF;
            }
    
            &:last-child{
                background-color: #242241;
            }
        }
    }

    .formHolder{
        padding: 0 4rem;
        margin: 9rem 0;
    
        h3{
            font-size: 1.6rem;
            line-height: 1.9rem;
            margin-bottom: 4rem;
            padding-bottom: 2rem;
        }
    
        form{
            label{
                font-size: 3rem;
                line-height: 104%;
            }
    
            input{
                background: none;
                outline: none;
                -webkit-appearance: none;
                border: none;
                border-bottom: 1px solid #B8B9B9;
                border-radius: 0;
                padding-bottom: 1.1rem;
                padding-left: 1rem;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 150%;
                color: #1F2023;
    
                &[type="text"], &[type="email"], &[type="tel"]{
                    width: 100%;
                    margin-right: unset;
                }
    
                &[type="radio"]{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    width: 100%;
                    height: 2.4rem;
                }
            }
    
            > div{
                > div{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 5rem;
                    margin-top: 2.5rem;
                    row-gap: 3rem;
    
                    > div{
                        align-items: center;
                        display: flex;
                        margin-right: 7rem;
                        position: relative;
                        padding-left: 4rem;
    
                        &:hover input ~ .checkmark {
                            border: 1px solid #1F2023;
                            border-radius: 100%;
                        }
    
                        .checkmark {
                            position: absolute;
                            top: -6px;
                            left: 0;
                            height: 25px;
                            width: 25px;
                            border: 1px solid #1F2023;
                            border-radius: 100%;
    
                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                                top: 7px;
                                left: 7px;
                                width: 11px;
                                height: 11px;
                                border-radius: 50%;
                                background: #0156FF;
                            }
                        }
    
                        input:checked ~ .checkmark:after {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}