.solutions{
    display: flex;
    position: relative;

    .contentText{
        cursor: default;
        position: relative;

        &.fix{
            &:before, &:after{
                content: "";
            }
        }

        &:before{
            position: fixed;
            left: 0;
            top: 11vh;
            background: linear-gradient(180deg, #0156FF, #0156ff52);
            width: 50vw;
            height: 25vh;
            z-index: 1;
        }

        &:after{
            position: fixed;
            left: 0;
            bottom: 0;
            background: linear-gradient(180deg, #0156FF52, #0156FF);
            width: 50vw;
            height: 25vh;
            z-index: 1;
        }
    }

    .contentMarker{
        cursor: default;
        min-height: 40rem;
    }

    .contentImages{
        cursor: default;
    }

    > div{
        padding: 5.2rem 12.1rem;
        background-color: #0156FF;
        width: calc(70% - 24.2rem);

        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: 2.5rem;
            color: #FAFAFA;
        }

        .topics{
            padding-top: 30vh;
            position: relative;

            h4{
                font-family: "GTFlexa", sans-serif, serif;
                font-weight: 400;
                font-size: 4.2rem;
                line-height: 104%;
                color: #FAFAFA;
                height: unset;
                width: 60.7rem;
                margin-bottom: 8vh;
                display: flex;
                justify-content: center;
                align-items: center;

                &:last-child{
                    margin-bottom: 16vh;
                }
            }
        }
    }

    img{
        max-height: 48vh;
        max-width: 58rem;
        height: 48vh;
        width: 58rem;
        object-fit: cover;
        position: absolute;
        right: 4vw;
        top: 25vh;
    }
}

div[class*="marquee__"]{
    span{
        width: 124.5rem;
    }

    > div{
        animation: newMarquee 10s linear infinite;
        animation-play-state: running;
    }
}

.opportunities{
    padding: 6.4rem 0 0;
    position: relative;

    h3{
        padding: 0 12.1rem;
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 2.5rem;
    }

    h2{
        padding: 0 12.1rem;
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 6.7rem;
        line-height: 104%;
        max-width: 83.4rem;
    }

    .opportunitiesHolder{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: hidden;
        padding-left: 36.4rem;
        align-self: flex-start;
        margin-top: 9.7rem;

        > div{
            &:after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(241, 243, 245, 0.6);
                transition: all .4s ease-in-out;
            }

            &.active{
                &:after{
                    background: rgba(0,0,0,0.4);
                }   
            }
        }
    }

    .next{
        position: absolute;
        bottom: 15.2rem;
        right: 38.6rem;
        opacity: 1;
        transition: all .4s ease-in-out;
        cursor: pointer;
        pointer-events: all;

        &.hide{
            opacity: 0;
            pointer-events: none;
        }
    }

    .prev{
        position: absolute;
        bottom: 15.2rem;
        left: 31.1rem;
        opacity: 0;
        transition: all .4s ease-in-out;
        cursor: pointer;
        pointer-events: none;

        &.show{
            opacity: 1;
            pointer-events: all;
        }

        svg{
            transform: rotate(180deg);
        }
    }
}

.advantages{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13rem 0 15rem;
    position: relative;
    overflow-x: hidden;

    h3{
        padding: 0 12.1rem;
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 2.5rem;
        text-align: center;
    }

    h2{
        padding: 0 12.1rem;
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 6.7rem;
        line-height: 104%;
        max-width: 83.4rem;
        text-align: center;
    }

    .advantagesHolder{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: hidden;
        align-self: flex-start;
        padding-left: 10.5rem;
        padding-right: 10.5rem;
        margin: 7.2rem auto 0;

        .advantage{
            display: flex;
            transition: all .8s ease-in-out;

            > div{
                &:first-child{
                    margin-right: 1rem;

                    p{
                        color: #0156FF;
                    }
                }

                p{
                    font-family: 'GTFlexa', sans-serif, serif;
                    font-weight: 400;
                    font-size: 3rem;
                    line-height: 150%;
                    text-align: center;
                    max-width: 27rem;

                    &.value{
                        font-size: 12rem;
                        line-height: 16rem;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }

            &.active{
                > div{
                    &:first-child{
                        margin-right: 1rem;
        
                        p{
                            color: #242241;
                        }
                    }
                }
            }
        }
    }

    .nextO{
        position: absolute;
        bottom: 4rem;
        right: 38.6rem;
        opacity: 1;
        transition: all .4s ease-in-out;
        cursor: pointer;
        pointer-events: all;

        &.hide{
            opacity: 0;
            pointer-events: none;
        }
    }

    .prevO{
        position: absolute;
        bottom: 4rem;
        left: 38.6rem;
        opacity: 0;
        transition: all .4s ease-in-out;
        cursor: pointer;
        pointer-events: none;

        &.show{
            opacity: 1;
            pointer-events: all;
        }

        svg{
            transform: rotate(180deg);
        }
    }

    img{
        position: absolute;
        max-width: 82rem;
        bottom: -18rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

video{
    width: 100%;
    height: 70vh;
    margin: 2rem auto 7rem;
    display: flex;
}

.form{
    padding: 17.3rem 12.1rem 18.5rem;
    background-color: #242241;
    z-index: 2;
    position: relative;

    .sheetsImageHolder{
        position: absolute;
        right: 4rem;
        top: -25%;
        display: flex;
        flex-direction: column;
        opacity: 0;
        overflow-x: hidden;
    
        img{
            max-width: 12rem;
            max-height: 12rem;
            object-fit: contain;
    
            &:nth-child(2){
                margin-bottom: -5rem;
                z-index: 3;
            }
    
            &:last-child{
                max-width: 10rem;
            }
        }
    }

    h4{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 11rem;
        line-height: 101%;
        color: #FAFAFA;
        margin-bottom: 3rem;

        span{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 11rem;
            line-height: 101%;
            color: #0156FF;
        }
    }

    a{
        color: #FAFAFA!important;

        svg{
            path{
                fill: #FAFAFA!important;
            }
            circle{
                stroke: #FAFAFA!important;
            }
        }
    }
}

@keyframes newMarquee {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(calc(-50% + 42vw), 0, 0);
	}
}

@media only screen and (max-width: 1024px) and (orientation:portrait){
    .solutions{
        > div{
            .topics{
                h4{
                    width: 40rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 480px){
    .solutions{
        display: flex;
        flex-direction: column;
        position: relative;

        .contentText{
            cursor: default;
            position: relative;

            &.fix{
                &:before, &:after{
                    content: unset;
                }
            }
        }

        .contentMarker{
            cursor: default;
            min-height: unset;
        }

        .contentImages{
            cursor: default;
        }

        > div{
            padding: 5.2rem 3.8rem 0;
            background-color: #0156FF;
            width: calc(100% - 7.6rem);

            h3{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.2rem;
                margin-bottom: 2.5rem;
                color: #FAFAFA;
            }

            .topics{
                padding-top: 6vh;
                position: relative;

                h4{
                    font-family: "GTFlexa", sans-serif, serif;
                    font-weight: 400;
                    font-size: 2.4rem;
                    line-height: 104%;
                    color: #FAFAFA;
                    height: unset;
                    width: unset;
                    margin-bottom: 6vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:last-child{
                        margin-bottom: 3vh;
                    }
                }
            }
        }

        img{
            display: none;
        }
    }
    
    div[class*="marquee__"]{
        span{
            width: 124.5rem;
        }
    
        > div{
            animation: newMarquee 10s linear infinite;
            animation-play-state: running;
        }
    }
    
    .opportunities{
        padding: 6.4rem 0 0;
        position: relative;
    
        h3{
            padding: 0 3.8rem;
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 2rem;
        }
    
        h2{
            padding: 0 3.8rem;
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 3.4rem;
            line-height: 104%;
            max-width: unset;
        }
    
        .opportunitiesHolder{
            display: flex;
            flex-wrap: nowrap;
            overflow-x: hidden;
            padding-left: 3.8rem;
            align-self: flex-start;
            margin-top: 4rem;
    
            > div{
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(241, 243, 245, 0.6);
                    transition: all .4s ease-in-out;
                }
    
                &.active{
                    &:after{
                        background: rgba(241, 243, 245, 0.0);
                    }   
                }
            }
        }
    
        .next{
            position: absolute;
            bottom: -5.2rem;
            right: 2.6rem;
            opacity: 1;
            transition: all .4s ease-in-out;
            cursor: pointer;
            pointer-events: all;
            transform: scale(.5);
    
            &.hide{
                opacity: 0;
                pointer-events: none;
            }
        }
    
        .prev{
            position: absolute;
            bottom: -5.2rem;
            left: 2.6rem;
            opacity: 0;
            transition: all .4s ease-in-out;
            cursor: pointer;
            pointer-events: none;
            transform: scale(.5);
    
            &.show{
                opacity: 1;
                pointer-events: all;
            }
    
            svg{
                transform: rotate(180deg);
            }
        }
    }
    
    .advantages{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8rem 0 5rem;
        position: relative;
    
        h3{
            padding: 0 3.8rem;
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 2rem;
            text-align: left;
        }
    
        h2{
            padding: 0 3.8rem;
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 3.6rem;
            line-height: 104%;
            max-width: unset;
            text-align: center;
        }
    
        .advantagesHolder{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-x: hidden;
            align-self: flex-start;
            margin-top: 3rem;
            padding-left: 3.8rem;
            padding-right: 3.8rem;
    
            .advantage{
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: unset;
                transition: all .8s ease-in-out;
    
                > div{
                    width: 80%;

                    &:first-child{
                        margin-right: 1rem;
                        width: 20%;
    
                        p{
                            color: #0156FF;
                        }
                    }
    
                    p{
                        font-family: 'GTFlexa', sans-serif, serif;
                        font-weight: 400;
                        font-size: 2rem;
                        line-height: 150%;
                        text-align: center;
                        max-width: unset;
    
                        &.value{
                            font-size: 5rem;
                            line-height: 6rem;
                            text-align: center;
                            text-transform: uppercase;
                        }
                    }
                }
    
                &.active{
                    > div{
                        &:first-child{
                            margin-right: 1rem;
            
                            p{
                                color: #242241;
                            }
                        }
                    }
                }
            }
        }
    
        .nextO{
            display: none;
        }
    
        .prevO{
            display: none;
        }
    
        img{
            position: absolute;
            max-width: 100%;
            bottom: -8rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
    
    video{
        height: 30vh;
    }

    .form{
        padding: 6rem 3.8rem 4rem;
        background-color: #242241;
        z-index: 2;

        .sheetsImageHolder{
            display: none;
        }
    
        h4{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 5rem;
            line-height: 101%;
            color: #FAFAFA;
            margin-bottom: 3rem;
    
            span{
                font-family: 'GTFlexa', sans-serif, serif;
                font-weight: 400;
                font-size: 5rem;
                line-height: 101%;
                color: #0156FF;
            }
        }
    
        a{
            color: #FAFAFA!important;
    
            svg{
                path{
                    fill: #FAFAFA!important;
                }
                circle{
                    stroke: #FAFAFA!important;
                }
            }
        }
    }
}