.marquee {
	width: 100vw;
	overflow: hidden;
	pointer-events: none;
	margin-bottom: 5rem;
	margin-top: 5rem;
}

.marqueeInner {
	width: fit-content;
	display: flex;
	position: relative;
	transform: translate3d(0, 0, 0);
	animation: marquee 10s linear infinite;
	animation-play-state: running;
	opacity: 1;
    transition-duration: 0.4s;
	transition: opacity 0.1s;
}

.marquee span {
	text-align: center;
    white-space: nowrap;
	font-size: 11rem;
	font-weight: 400;
	line-height: 1.15;
    width: 94.5rem;
    font-family: 'GTFlexa', sans-serif, serif;
    font-weight: 400;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #1F2023;

    &.blue{
        -webkit-text-stroke-color: #0156FF;
    }
}

@keyframes marquee {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(calc(-50% + 31vw), 0, 0);
	}
}