.faqs{
    padding: 14.8rem 10.5rem 13rem;

    h3{
        font-family: 'GTFlexa', sans-serif, serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 2.4rem;
    }
}

.lastImage{
    display: block;
    text-align: center;
    
    img{
        margin: 0 auto;
        max-width: 50rem;
    }
}

@media only screen and (max-width: 480px){
    .faqs{
        padding: 5rem 3.8rem 0;
    
        h3{
            font-family: 'GTFlexa', sans-serif, serif;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 2.4rem;
        }
    }
}